@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/slick-carousel/slick/slick.scss";
// endbower

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/video";
@import "layouts/tinymce";

@import "../fonts/Karbon/Karbon.scss";
@import url('https://fonts.googleapis.com/css?family=Rubik');
