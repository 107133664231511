.sidebar,
.entry-subpages {
  padding: 0 5px;
  border-right: 1px solid #000;
  background-color: #fff;
  height: 100vh;
  position: fixed;
  z-index: 1000;

  @media (max-width: 668px) {
    position: relative;
    height: auto !important;
  }

  .header {
    z-index: 20;
  }

  .sidebar-intro {
    font-size: 18px;
    line-height: 26px;
    height: 96px;
    width: 100%;
    top: 50%;
    position: absolute;
    margin-left: -5px;
    margin-top: 1px;
    text-align: center;

    @media (min-width: 1910px) {
      font-size: 24px;
      line-height: 31px;
    }

    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 21px;
    }

    .sidebar-intro-inner {
      border-top: 1px solid $darkgrey;
      margin: 0 35px;
      padding: 15px 45px;

      @media (max-width: 1024px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .sidebar-content-container {
    border-top: 1px solid #000;
    overflow: scroll;
    position: relative;
    -webkit-overflow-scrolling: touch;
  }

  .sidebar-content {
    display: none;

    &.slide-up {
      transition: margin-top 0.3s ease-out;
      margin-top: 105px !important;
    }
  }

  .sidebar-tiles {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 0;

    .sidebar-tile {
      background: no-repeat center;
      background-size: cover;
      width: 100%;
      height: 100vh;
    }
  }

  .logo {
    position: absolute;
    width: auto;
    display: block;
    text-align: center;
    padding: 0 35px;
    bottom: 35px;

    img {
      max-width: 100%;
      height: 100px;
      display: block;
    }
  }


  .logo, .sidebar-intro {
    opacity: 1;
    transition: opacity 0.5s ease-in;

    &.fade-out {
      opacity: 0;
      z-index: -1;
    }
  }

  article {
    border-top: 1px solid #000;
    border-bottom: 0 !important;
    padding: 40px 55px;

    @media (max-width: 1024px) {
      padding: 40px 50px;
    }

    &:first-of-type {
      border: 0;
    }

    h1, h2 {
      margin: 0;
    }

    h1 {
      font-size: 26px;
      line-height: 31px;
      font-weight: normal;
      margin-bottom: 25px;
    }

    h2 {
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
      margin-bottom: 35px;
      letter-spacing: 1px;
    }
  }
}

.single-store, .single-post, .single-news, .single-event, .jubileum {
  .sidebar {
    padding-left: 0;
    padding-right: 0;
  }
}

.single-store {
  ul.menu-buttons {
    margin-left: 5px;
  }
}

.main {
  border-right: 1px solid #000;
  background-color: #fff;
  height: 100vh;

  article {
    height: 100vh;
    position: relative;

    .entry-header, .entry-subheader {
      border-bottom: 1px solid #000;
      padding: 11px 10px;

      .entry-header-inner, .entry-subheader-inner {
        background-color: #000;
        padding: 10px 25px;

        @media (min-width: 1910px) {
          padding-top: 7px;
          padding-bottom: 6px;
        }

        h1, h2 {
          font-size: 15px;
          line-height: 15px;
          margin: 0;
          color: #fff;
          display: inline-block;

          @media (max-width: 1024px) {
            font-size: 11px;
            line-height: 11px;
          }
        }

        h1, .entry-type {
          font-weight: 500;
          text-transform: uppercase;

          &.entry-title {
            margin-right: 5px;
          }

          @media (min-width: 1910px) {
            font-size: 20px;
          }
        }

        h2 {
          font-weight: normal;

          @media (min-width: 1910px) {
            font-size: 20px;
          }
        }

        .entry-navigation {
          float: right;

          .entry-navigation-link {
            background-color: #fff;
            color: #000;
            width: 18px;
            height: 18px;
            line-height: 25px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &.entry-navigation-link-left {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .entry-content-container {
      margin-top: 35px;
      position: relative;
      overflow: scroll;
      margin-right: 5px;
      -webkit-overflow-scrolling: touch;

      .entry-content-container {
        margin-top: 0;
      }
    }

    .entry-content {
      padding: 0px 35px 35px 35px;
    }

    .entry-subpages {
      display: none;
      padding: 0 35px 35px;
      margin-top: 50px;
      border-right: 0;

      @media (max-width: 1024px) {
        display: block;
      }

      article {
        border-top: 1px solid #000;
        padding: 40px 0;
      }
    }

    .entry-meta {
      border-top: 1px solid #000;
      padding-top: 25px;
      margin: 0 75px;
      margin-top: 25px;
      text-align: center;
      line-height: 20px;

      @media (min-width: 1910px) {
        margin: 0 100px;
        line-height: 25px;
      }

      @media (max-width: $screen-sm-min) {
        margin: 0 25px;
      }

      .entry-meta-quote {
        text-transform: uppercase;
        margin: 15px 0;
      }

      .entry-meta-website {
        font-weight: bold;
        color: #000;
      }
    }

    .entry-footer {
      position: absolute;
      bottom: 0;
      width: 100%;

      .entry-footer-inner {
        font-size: 17px;
        line-height: 26px;
        text-align: center;
        background-color: $grey;
        margin: 0 5px;
        margin-top: 50px;
        padding: 35px;
        position: relative;

        a {
          color: #000;
        }

        .entry-footer-badge {
          border-radius: 50%;
          width: 60px;
          height: 60px;
          display: block;
          border: 5px solid #fff;
          position: absolute;
          top: -40px;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          background: url('../images/groenepassage-badge.svg') no-repeat center #fff;
        }
      }
    }
  }
}

.pagination {
  position: absolute;
  margin: 0;
  border-radius: 0;
  width: 5%;
  height: 100vh;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2;

  span {
    border-radius: 50%;
    color: #000;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    min-width: 36px;
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
    display: inline-flex;
  }
}

.previous {
  left: 0;
}

.next {
  right: 0;
}

.tiles {
  position: relative;
  background: #fff;

  &.posts {
    overflow: auto;
  }

  .tile {
    color: #fff;

    .tile-image {
      background-position: center top;
    }

    &.tile-align-top {
      .tile-image {
        background-position: center top;
      }
    }

    &.tile-align-bottom {
      .tile-image {
        background-position: center bottom;
      }
    }

    &.tile-align-center {
      .tile-image {
        background-position: center;
      }
    }

    .tile-inner {
      background: no-repeat center;
      background-size: contain;
      height: 100%;

      .tile-overlay {
        border: 10px solid #fff;
        height: 100%;
        position: relative;
        background: no-repeat center;
        background-size: contain;
        height: 100%;

        @media (max-width: $screen-sm-min) {
          border: 0;
        }

        .tile-sharing {
          position: absolute;
          top: 0;
          padding: 15px;
          z-index: 1;

          a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #000;
            width: 22px;
            height: 22px;
            color: #fff;
            text-align: center;
            line-height: 25px;
            margin-right: 10px;

            @media (max-width: 1280px) {
              width: 18px;
              height: 18px;

              svg {
                width: 0.8em;
                height: 0.8em;
              }
            }
          }
        }

        .tile-body {
          text-align: center;
          padding: 15px;
          padding-top: 70px;
          text-decoration: none;
          color: #fff;

          a {
            color: #fff;
            text-decoration: none;
          }

          h3 {
            text-transform: uppercase;
            color: #fff;
            font-size: 25px;
            line-height: 28px;
            letter-spacing: 1px;
            margin: 0 65px;
            margin-bottom: 30px;
            padding: 30px 0;

            @media (min-width: 1910px) {
              font-size: 41px;
              line-height: 46px;
            }

            @media (max-width: 1280px) {
              margin: 0 35px;
            }

            @media (max-width: 1024px) {
              font-size: 22px;
              line-height: 25px;
              margin-left: 0;
              margin-right: 0;
            }
          }
        }

        .tile-link {
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
          letter-spacing: 1px;
          display: inline-block;
          margin-top: 20px;

          @media (min-width: 1910px) {
            font-size: 18px;
            line-height: 21px;
          }
        }

        .tile-arrow-left, .tile-arrow-right, .tile-arrow-up, .tile-arrow-down {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          color: #000;

          @media (max-width: 1910px) {
            width: 12px;
            height: 12px;
            margin-top: 3px;

            svg {
              width: 0.6em;
              height: 0.6em;
            }
          }
        }

        .tile-arrow-left {
          float: left;
          margin-right: 10px;
        }

        .tile-arrow-right {
          float: right;
          margin-left: 10px;
        }

        .tile-excerpt {
          margin: 0 65px;
        }

        .tile-date, .tile-label {
          font-size: 22px;
          line-height: 28px;
          text-align: center;
          text-transform: uppercase;
          text-decoration: none;
          width: 64px;
          padding: 0 10px;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          color: #000;
          background-color: #fff;

          @media (max-width: 1024px) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .tile-date {
          padding: 5px 17px;
          width: 70px;

          @media (max-width: 1024px) {
            width: 55px;
            padding: 5px 15px;
          }

          span {
            display: block;

            &.month, &.type {
              border-top: 1px solid #000;
            }

            &.type {
              font-weight: bold;
            }
          }
        }

        .tile-label {
          font-size: 13px;

          .type {
            font-size: 15px;
            font-weight: 500 !important;
            border-top: 0 !important;
            display: block;
          }

          .tile-arrow-down {
            display: flex;
            margin-left: auto;
            margin-right: auto;
            transition: transform 0.5s ease-in-out;
            transform: rotate(180deg);
          }

          .read-more {
            border-bottom: 1px solid black;
            width: 40%;
            display: inline-block;
          }
        }

        .tile-footer {
          color: #000;
          font-size: 12px;
          line-height: 20px;
          text-transform: uppercase;
          text-align: center;
          width: 100%;
          padding: 15px;

          @media (min-width: 1910px) {
            font-size: 16px;
            line-height: 16px;
          }

          .tile-footer-top, .tile-footer-bottom {
            padding: 2px 0;

            @media (min-width: 1910px) {
              padding: 4px 0;
            }
          }

          .tile-footer-top {
            display: inline-block;
            border-bottom: 1px solid #000;
          }

          .tile-footer-bottom {
            font-weight: 500;
          }
        }

        .tile-date, .tile-label, .tile-footer {
          position: absolute;
          bottom: 0px;
        }
      }
    }

    &.event {
      cursor: pointer;

      .tile-inner {
        background-size: cover;
        overflow: hidden;

        .tile-body {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          color: #fff;
        }

        .tile-overlay {
          display: none;
          background-color: transparentize(#000, 0.2);

          h3 {
            font-weight: normal;
            position: relative;
            margin-top: 30px;

            &:after {
              content: "";
              position: absolute;
              left: -20%;
              top : 0;
              margin-left: 50%;
              height : 1px;
              width : 40%;  /* or 100px */
              border-top: 1px solid #fff;
            }
          }
        }

        .tile-link {
          .tile-arrow-left, .tile-arrow-right, .tile-arrow-up {
            transform: rotate(180deg);
            transition: transform 0.5s ease-in-out;
          }
        }

        .tile-label {
          .tile-arrow-down {
            transition: transform 0.5s ease-in-out;
          }
        }

        .tile-image, .tile-colour {
          position: relative;
          z-index: 0;
          float: left;
          width: 100%;
          height: 100%;
          background-size: cover;
          transition: margin-top 0.4s ease-in-out;
        }
      }

      &:hover, &.active {
        .tile-inner {
          .tile-image {
            margin-top: 100%;
          }

          .tile-link {
            .tile-arrow-left, .tile-arrow-right {
              transform: rotate(0deg);
            }
          }
        }

        .tile-overlay {
          display: block;
        }
      }
    }

    &.bioscope {
      cursor: pointer;

      .tile-inner {
        background-size: cover;
        overflow: hidden;
        border: 10px solid white;

        .tile-body {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          color: #fff;
          padding-top: 30px;
        }

        .tile-overlay {
          border: 0;
          display: block;
          // background-color: transparentize(#000, 0.2);

          h3 {
            font-weight: normal;
            position: relative;
            margin-top: 30px;
            padding: 0;
          }

          h4 {
            font-weight: 200;
            text-transform: uppercase;
          }

          h3, h4 {
            font-size: 45px;
            line-height: 38px;
            margin-bottom: 0;

            @media (min-width: 1910px) {
              font-size: 55px;
              line-height: 50px;
            }
          }

          .tile-header,
          .tile-footer {
            text-align: center;
            width: 100%;
            position: absolute;

            span {
              display: block;
            }
          }

          .tile-header {
            .tile-headlines-title {
              text-transform: uppercase;
              font-size: 18px;
              line-height: 20px;
              margin-bottom: 0;

              @media (min-width: 1910px) {
                font-size: 20px;
                line-height: 24px;
              }

            }

            .tile-headlines {
              font-size: 25px;
              line-height: 28px;
              margin-bottom: 0;

              @media (min-width: 1910px) {
                font-size: 35px;
                line-height: 38px;
              }
            }
          }

          .tile-footer {
            bottom: 0;

            .tile-read-more,
            .tile-summary {
              padding: 5px 0;
            }

            .tile-summary {
              position: relative;
              font-weight: 600;

              &:after {
                content: "";
                position: absolute;
                left: -15%;
                top : 0;
                margin-left: 50%;
                height : 1px;
                width : 30%;  /* or 100px */
                border-top: 1px solid black;
              }
            }
          }
        }

        .tile-link {
          .tile-arrow-left, .tile-arrow-right {
            transform: rotate(180deg);
            transition: transform 0.5s ease-in-out;
          }
        }

        .tile-image, .tile-colour {
          position: relative;
          z-index: 0;
          float: left;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          transition: margin-top 0.4s ease-in-out;
        }
      }

      // &:hover, &.active {
      //   .tile-inner {
      //     .tile-image {
      //       margin-top: -100%;
      //     }
      //
      //     .tile-link {
      //       .tile-arrow-left, .tile-arrow-right {
      //         transform: rotate(0deg);
      //       }
      //     }
      //   }
      //
      //   .tile-overlay {
      //     display: block;
      //   }
      // }
    }

    &.store, &.message {
      .tile-inner {
        position: relative;

        .tile-header {
          position: absolute;
          width: 100%;
          height: 100%;
          text-align: center;
          z-index: 1;
          display: none;

          .tile-header-inner {
            display: block;
            padding: 5px 20px;
            color: #000;

            h3, h4 {
              text-transform: uppercase;
              font-size: 12px;
              line-height: 18px;
              margin: 0;

              @media (min-width: 1910px) {
                font-size: 18px;
                line-height: 21px;
              }
            }

            h4 {
              font-weight: 400;
              display: inline-block;
              border-bottom: 1px solid #000;
            }
          }

          &:hover, &:active {
            text-decoration: none;
          }
        }
      }

      &:hover, &:active, &.triggered {
        .tile-inner {
          .tile-header {
            display: block !important;
          }
        }
      }
    }

    &.post, &.news, &.jubilee {
      cursor: pointer;

      .tile-inner {
        background-size: cover;
        overflow: hidden;

        .tile-body {
          width: 100%;
          height: 100%;
          left: 0;
          position: absolute;
        }

        .tile-overlay {
          background-color: transparent;
          z-index: 1;

          h3 {
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            min-height: 155px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-height: 800px) {
              min-height: 120px;
            }
          }
        }

        .tile-image, .tile-colour {
          position: relative;
          z-index: 0;
          float: left;
          width: 100%;
          height: 100%;
          background-size: cover;
          transition: margin-top 0.4s ease-in-out;
        }
      }

      &:hover {
        .tile-inner {
          .tile-colour {
            margin-top: -100%;
          }
        }

        .tile-overlay {
          background-color: transparent !important;

          .tile-footer {
            display: none;
          }
        }
      }
    }

    &.news {
      .tile-inner {
        .tile-overlay {
          h3 {
            font-weight: 600;
          }

          .tile-body {
            left: 0;
          }

          .tile-link {
            visibility: visible;
            color: #fff;
          }
        }
      }

      &:hover, &:active {
        .tile-inner {
          .tile-overlay {
            h3 {
              visibility: hidden;
            }

            .tile-link {
              visibility: hidden;

              .tile-arrow-left, .tile-arrow-right {
                transform: rotate(0deg);
              }
            }
          }
        }
      }
    }

    &.post,
    &.jubilee {
      .tile-inner {
        .tile-colour {
          margin-top: -100%;

          @media (max-width: 1024px) {
            display: none;
          }
        }

        .tile-image {
          border: 10px solid white;
        }

        .tile-overlay {
          visibility: hidden;

          h3 {
            font-weight: normal;
            text-transform: none;
            color: white;
            position: relative;
            border-color: rgba(255, 255, 255, 0.6);

            &:before, &:after {
              position: absolute;
              content: '';
              width: 20px;
              height: 20px;
              display: block;
              background: no-repeat center;
            }

            &:before {
              background-image: url('../images/pullquote-left.svg');
              top: -10px;
              left: -25px;
            }

            &:after {
              background-image: url('../images/pullquote-right.svg');
              bottom: -10px;
              right: -25px;
            }

            @media (max-width: 1024px) {
              margin-left: 20px;
              margin-right: 20px;
            }
          }

          .tile-link {
            display: inline-block;
            visibility: hidden;
            margin: 0 auto;
            left: 0;
            right: 0;
            bottom: 85px;
            color: #fff;

            .tile-arrow-left, .tile-arrow-right {
              transform: rotate(180deg);
              transition: transform 0.5s ease-in-out;
            }
          }

          .tile-label {
            width: 80%;
            background-color: transparent;
          }
        }
      }

      &:hover, &:active {
        .tile-inner {
          .tile-overlay {
            visibility: visible;

            h3 {
              visibility: visible;
            }

            .tile-link {
              visibility: hidden;

              .tile-arrow-left, .tile-arrow-right {
                transform: rotate(0deg);
              }
            }

            .tile-label {
              .tile-arrow-down {
                transform: rotate(0deg);
              }
            }
          }
        }
      }
    }

    &.store {
      cursor: pointer;
    }

    &.page:not(.jubilee) {
      cursor: pointer;

      .tile-inner {
        .tile-overlay {
          .tile-body {
            margin: 0;
            display: none;

            h3, h4 {
              font-size: 15px;
              line-height: 24px;
              margin: 0;
              padding: 0;
              color: #000;
              text-transform: uppercase;
              display: inline-block;
            }

            h4 {
              border-bottom: 1px solid #000;
            }
          }
        }
      }

      &:hover, &:active, &.active {
        .tile-inner {
          .tile-overlay {
            .tile-body {
              visibility: visible;
            }
          }
        }
      }
    }

    &:nth-child(3), &:nth-child(4) {
      &.page {
        .tile-body {
          position: absolute;
          width: 100%;
          bottom: 0;
        }
      }
    }
  }
}

.tile-slides {
  &:nth-child(odd) {
    .tile-sharing {
      left: 0;
      right: auto;
    }
  }

  &:nth-child(even) {
    .tile-sharing {
      left: auto;
      right: 0;
    }
  }

  &:nth-child(3), &:nth-child(4) {
    .store, .message {
      .tile-header {
        .tile-header-inner {
          position: relative;
          width: 100%;
          height: 100%;

          .tile-header-content {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 5px
          }
        }
      }
    }
  }

  &.rtl .tile-overlay * {
    direction: ltr !important;
  }

  .tile {
    width: 100%;
  }
}

.tile-overflow {
  background-color: $brand-primary;

  &.tile-upper {
    background-color: darken($brand-primary, 6);
  }
}

.single-post, .single-event, .single-news, .page, .error404 {
  .main {
    .entry-header {
      border: 0;
      margin-top: 30px;
      padding-left: 35px;
      padding-right: 35px;

      h1 {
        font-size: 22px;
        line-height: 24px;
        font-weight: normal;
        text-transform: uppercase;
        margin: 0;
      }
    }

    .entry-content {
      padding-top: 0;
    }
  }

  .sidebar {
    .sidebar-content {
      display: block;
    }

    .sidebar-intro, .logo {
      display: none;
    }
  }
}

.single-post, .single-event, .single-news {
  .tiles {
    .tile-slides {
      &:first-child {
        .tile {
          border-bottom: 1px solid #000;
        }

      }
    }
  }
}

.single-post {
  .tiles {
    .tile-slides {
      .tile {
        border-bottom: 1px solid #000;
      }

      &:last-child {
        .tile {
          border-bottom: 0;
        }
      }
    }
  }
}


.stores, .store-slides {
  .store {
    background: no-repeat center;
    background-size: cover;
    background-position: top center;
    width: 100%;
    height: 100vh;
    display: none;
    text-decoration: none;
    position: relative;

    &.active {
      display: block;
    }

    header {
      background-color: #fff;
      top: 10px;
      left: 10px;
      width: 50vh;
      padding: 12px 25px 8px 25px;
      position: absolute;

      @media (min-width: 2500px) and (min-height: 1080px) {
        width: 45%;
      }

      @media (min-width: 1910px) {
        padding: 8px 25px 9px 25px;
      }

      @media (max-width: 1280px) {
        width: 45%;
      }

      h3, h4 {
        font-size: 15px;
        line-height: 15px;
        display: inline-block;
        color: #000;
        margin: 0;

        @media (min-width: 1910px) {
          font-size: 20px;
          line-height: 20px;
        }
      }

      h3 {
        text-transform: uppercase;
        margin-right: 5px;
      }

      h4 {
        font-weight: normal;
      }
    }

    .catchwords {
      font-size: 48px;
      line-height: 54px;
      font-weight: 300;
      color: #fff;
      display: inline-block;
      width: 50vh;
      height: calc(50vh - 10px);
      background-color: transparentize($brand-primary, 0.2);
      position: absolute;
      right: 10px;
      top: 10px;

      @media (min-width: 2500px) and (min-height: 1080px) {
        width: 45%;
        height: calc(50% - 10px);
      }

      @media (min-width: 1910px) {
        font-size: 60px;
        line-height: 66px;
      }

      @media (max-width: 1280px) {
        width: 45%;
      }

      @media (max-height: 785px) {
        font-size: 6.5vh;
        line-height: 6.5vh;
      }

      .catchwords-inner {
        position: relative;
        height: 100%;

        .catchword-list {
          padding: 25px 45px 50px 45px;
        }

        .catchwords-footer {
          text-align: left;
          position: absolute;
          bottom: 25px;
          width: 100%;

          .catchwords-footer-inner {
            border-top: 1px solid #fff;
            margin: 0 45px;

            img {
              width: 250px;

              @media (max-height: 785px) {
                width: 100%;
                max-width: 210px;
              }
            }
          }
        }
      }
    }

    &.store-de-groene-weg, &.store-gimsel, &.store-aditi {
      .catchwords {
        left: 10px;
        right: auto;
        top: auto;
        bottom: 10px;
      }
    }

    &.store-van-binnen {
      .catchwords {
        left: auto;
        right: 10px;
        top: 10px;
        bottom: auto;
      }
    }

    &.store-wereldwinkel, &.store-spirit {
      .catchwords {
        left: auto;
        right: 10px;
        top: auto;
        bottom: 10px;
      }
    }

    &.store-van-binnen {
      background-position: center bottom;
    }

/*    &.store-gimsel, &.store-van-binnen, &.store-spirit, &.store-wereldwinkel {
      background-position: center bottom;
    }
*/  }
}

.store-slides {
  position: fixed;
  z-index: 1000;
  top: 0;
  display: none;

  &.active {
    display: block;
  }

  .store-slides-inner {
    position: relative;
  }
}

.archive {
  .tiles {
    .tile-cluster {
      .tile-slides {
        border-right: 1px solid #000;

        .tile {
          border-bottom: 1px solid #000;
        }
      }

      &.tile-cluster-wide {
        .tile-slides {
          &:nth-child(3), &:nth-child(4) {
            .tile {
              border-bottom: 0;
            }
          }
        }
      }

      &.tile-cluster-small {
        .tile-slides {
          &:nth-child(2) {
            .tile {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.post-type-archive-event,
.post-type-archive-bioscope,
.single-bioscope {
  .sidebar {
    .event,
    .bioscope {
      border-bottom: 1px solid #000;
      padding-top: 35px;
      padding-bottom: 35px;
      min-height: 165px;

      header {
        display: inline;

        h1, h2 {
          margin: 0;
        }

        h1 {
          font-size: 15px;
          line-height: 15px;
          text-transform: uppercase;
          margin-bottom: 5px;

          @media (min-width: 1910px) {
            font-size: 20px !important;
            line-height: 20px !important;
          }
        }

        h2 {
          font-size: 22px;
          line-height: 28px;
          font-weight: 400;
          float: left;
          color: #fff;
          width: 64px;
          display: inline-block;
          margin-right: 15px;
          margin-bottom: 0px;
          padding: 5px 14px;
          text-align: center;
          background-color: $brand-primary;

          @media (max-width: 1024px) {
            font-size: 16px;
            width: 55px;
            line-height: 24px;
            padding: 5px 15px;
          }

          span {
            display: block;

            &.month {
              border-top: 1px solid #fff;
            }
          }
        }
      }
    }
  }

  .sidebar {
    .event,
    .bioscope {
      border-bottom: 1px solid #000;
      padding-top: 35px;
      padding-bottom: 35px;
      min-height: 165px;

      header {
        display: inline;

        h1, h2 {
          margin: 0;
        }

        h1 {
          font-size: 15px;
          line-height: 15px;
          text-transform: uppercase;
          margin-bottom: 5px;

          @media (min-width: 1910px) {
            font-size: 20px !important;
            line-height: 20px !important;
          }
        }

        h2 {
          font-size: 22px;
          line-height: 28px;
          font-weight: 400;
          float: left;
          color: #fff;
          width: 64px;
          display: inline-block;
          margin-right: 15px;
          margin-bottom: 0px;
          padding: 5px 14px;
          text-align: center;
          background-color: $brand-primary;

          @media (max-width: 1024px) {
            font-size: 16px;
            width: 55px;
            line-height: 24px;
            padding: 5px 15px;
          }

          span {
            display: block;

            &.month {
              border-top: 1px solid #fff;
            }
          }
        }
      }
    }

    .bioscope {
      header {
        h2 {
          font-size: 16px;
          line-height: 24px;
          width: 85px;
          height: 85px;
          padding: 5px 10px;

          @media (max-width: 1024px) {
            font-size: 14px;
            line-height: 20px;
          }

          span {
            display: block;
            padding: 5px 0;

            &.year {
              font-size: 22px;
              line-height: 28px;
              border-top: 1px solid #fff;
            }
          }
        }
      }
    }
  }

  .tiles {
    .tile {
      &.event {
        .tile-overlay {
          display: block;
          background-color: transparent;

          .tile-body {
            visibility: hidden;
          }
        }

        &:hover, &:active, &.active {
          .tile-inner {
            background-image: none !important;
          }

          .tile-overlay {
            .tile-body {
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

.post-type-archive-bioscope,
.single-bioscope {
  @media (max-width: 668px) {
    .editions {
      display: none;
    }
  }

  .edition-list {
    background-color: white;
    width: 50%;
    min-height: 100vh;
    position: relative;
    text-align: center;
    padding: 25px 0;

    @media (max-width: 768px) {
      min-height: 50vh;
    }

    @media (min-width: 1921px) and (min-height: 1081px) {
      min-height: 1080px;
      height: 1080px;
    }

    h4 {
      text-align: center;
      font-weight: 400;
      text-transform: uppercase;
      margin: 0;
      padding: 50px 0;
    }

    .edition-list-container {
      position: relative;
      // height: calc(100vh - 235px);
      height: 100vh;
      overflow: scroll;
      text-align: center;

      @media (max-width: 768px) {
        height: calc(50vh - 235px) !important;
      }

      @media (min-width: 1921px) and (min-height: 1081px) {
        min-height: 1080px;
        height: 1080px;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0 70px 70px 70px;
      list-style-type: none;
      justify-content: space-around;

      @media (max-width: 768px) {
        padding: 0 30px;
      }

      &::after {
        content: "";
        flex: auto;
        width: 135px;
        margin-right: 65px;

        @media (max-width: 1280px) {
          margin-right: 15px;
        }
      }

      li {
        display: inline-block;
        margin-right: 35px;
        margin-bottom: 20px;
        flex: 1;

        @media (max-width: 1280px) {
          margin-right: 15px;
        }

        // &:nth-child(3n+3) {
        //   margin-right: 0;
        // }

        a {
          display: block;

          img {
            width: 120px;

            @media (max-width: 1280px) {
              width: 80px;
            }
          }
        }
      }
    }

    .edition-list-footer {
      position: absolute;
      bottom: 0;
      width: 250px;
      margin: 0 auto;
      margin-bottom: 25px;
      left: 0;
      right: 0;
      text-align: center;

      .edition-list-year,
      .edition-list-title {
        display: block;
      }

      .edition-list-year {
        font-weight: 300;
        text-transform: uppercase;
      }

      .edition-list-title {
        font-weight: 400;
        border-top: 1px solid #000;
        text-transform: uppercase;
      }
    }
  }

  .tiles {
    border-right: 1px solid #000;
    height: 100vh;
    overflow: hidden;

    @media (min-width: 1921px) and (min-height: 1081px) {
      height: 1080px;
    }

    .tiles-wrapper {
      @include clearfix;
      transition: margin-top 0.3s ease-out;
    }

    .tile {
      border-bottom: 1px solid #000;
      height: calc(50vh + 1px);

      @media (min-width: 1921px) and (min-height: 1081px) {
        height: 540px;
      }

      .tile-inner {
        .tile-overlay {
          .tile-link {
            .tile-arrow-up {
              border: 1px solid #000;
              transition: transform 0.5s ease-in-out;
              width: 30px;
              height: 30px;

              svg {
                width: 1em;
                height: 1em;
              }
            }
          }

          .tile-label {
            .tile-arrow-down {
              border: 1px solid #000;
              width: 30px;
              height: 30px;

              svg {
                width: 1em;
                height: 1em;
              }
            }
          }
        }
      }
    }

    &:hover,
    &:active {
      .tile {
        .tile-inner {
          .tile-overlay {
            .tile-link {
              .tile-arrow-up {
                transform: rotate(-180deg);
              }
            }

            .tile-label {
              .tile-arrow-down {
                transform: rotate(0deg);
              }
            }
          }
        }
      }
    }

    &.active {
      .tiles-wrapper {
        margin-top: calc(-50vh - 1px);

        @media (min-width: 1921px) and (min-height: 1081px) {
          margin-top: -540px;
        }

        .tile {
          .tile-inner {
            .tile-overlay {
              .tile-link {
                .tile-arrow-up {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }

      &:hover,
      &:active {
        .tiles-wrapper {
          .tile {
            .tile-inner {
              .tile-overlay {
                .tile-link {
                  .tile-arrow-up {
                    transform: rotate(0deg);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

article {
  .entry-content {
    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }

    h1, h2, h3 {
      font-size: 14px;
      line-height: 14px;
      font-weight: normal;
      text-transform: uppercase;

      @media (min-width: 1910px) {
        font-size: 20px;
        line-height: 20px;
      }
    }

    h3, h4, h5, h6 {
      margin-top: 20px;
    }

    h3 {
      &:first-of-type {
        font-weight: 500;
        margin-bottom: 20px;
      }
    }

    strong {
      font-weight: 500;
    }

    h4 {
      font-size: 13px;
      line-height: 18px;

      @media (min-width: 1910px) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    h5 {
      font-size: 12px;

      @media (min-width: 1910px) {
        font-size: 15px;
      }
    }

    h6 {
      font-size: 12px;
      font-style: italic;

      @media (min-width: 1910px) {
        font-size: 15px;
      }
    }

    img {
      max-width: 100%;
    }

    a {
      color: $darkgrey;
      text-decoration: underline;

      &:hover, &:active {
        color: #000;
      }
    }

    .entry-content-image {
      margin-bottom: 35px;
      display: none;
      width: 100%;

      @media (max-width: $screen-sm-min) {
        display: block;
      }
    }
  }

  header {
    .entry-content-image {
      margin: 0 auto;
      margin-bottom: 35px;
      text-align: center;
      display: none;
      width: 100%;

      @media (max-width: $screen-sm-min) {
        display: block;
      }
    }
  }

  .entry-article-footer {
    color: #999;
    margin-top: 15px;
    text-transform: uppercase;

    .sharing-links-text {
      display: block;
      font-size: 10px;
      letter-spacing: 1px;
      margin-bottom: 5px;

      @media (min-width: 1910px) {
        font-size: 14px;
      }
    }

    a.sharing-link {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #efe9e9;
      width: 18px;
      height: 18px;
      color: #999;
      text-align: center;
      line-height: 20px;
      margin-right: 5px;

      .icon {
        font-size: 10px;
      }

      @media (min-width: 1910px) {
        width: 30px;
        height: 30px;
        font-size: 25px;

        svg {
          width: 1.3em;
          height: 1.3em;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover, &:active {
        color: #000;
      }
    }
  }
}

article#openingstijden {
  .entry-content {
    h3:first-of-type {
      font-weight: 400;
      margin-bottom: 0px;
    }
  }
}

.page .sidebar-content, .sidebar-pages {
  &.slide-up {
    margin-top: 105px !important;
  }

  h1 {
    font-size: 14px !important;
    line-height: 14px !important;
    font-weight: normal;
    text-transform: uppercase;

    @media (min-width: 1910px) {
      font-size: 20px !important;
      line-height: 20px !important;
    }
  }
}

.page {
  .sidebar-tiles {
    .sidebar-tile {
      background-image: url('../images/page-placeholder01.jpg');
    }
  }

  .tiles {
    &.pages {
      background: url('../images/page-placeholder02.jpg') no-repeat;
      background-size: cover;
    }
  }
}

.home {
  .tiles {
    .tile {
      .tile-inner {
        .tile-colour {
          display: none;
        }

        .tile-image {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }

        .tile-overlay {
          display: none;

          .tile-body {
            background-color: transparentize(#000, 0.6);
            height: 100%;
          }

          h3 {
            color: #fff;
          }
        }

        .tile-entry-overlay {
          position: relative;
          z-index: 2;
        }
      }

      &.post {
        .tile-inner {
          background-size: contain;
        }
      }

      &.message {
        .tile-overlay {
          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 155px;

            @media (max-height: 800px) {
              min-height: 120px;
            }
          }
        }
      }

      &:hover, &:active, &.triggered {
        .tile-inner {
          .tile-image {
            margin-top: 0%;
          }
        }

        .tile-overlay, .tile-body {
          display: block;

          h3 {
            visibility: visible !important;
          }
        }

        @media (max-width: 668px) {
          .tile-overlay {
            display: none;
          }
        }
      }
    }
  }
}

.archive {
  .ajax-receiver {
    .tiles, .tile-wrapper {
      transition: margin-left 0.5s ease-out;
    }
  }
}
