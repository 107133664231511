body.page-template-video {
    .header {
        position: absolute;
        width: 100%;
        z-index: 100;
    }

    article {
        .entry-content-asset {
            height: 1080px;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}
