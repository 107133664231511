// Grid system
.wrap {
  margin: 0;
}

.sidebar, .main {
  float: left;
  padding: 0;
  width: 430px;
}

.main {
  margin-left: 430px;
}

.archive-pagination {
  display: none;
}

.tiles, #footer .site-menu .main {
  @include clearfix;
  width: calc(100% - 430px);
  height: 100vh;
  margin-left: 430px;
  float: left;
  padding: 0;

  .tile {
    float: left;
    width: 50%;
    height: 50vh;
  }
}

.tile-entry-links {
  @include clearfix;
  width: calc(100% - 430px);
  height: 100%;
  position: absolute;
  z-index: 2;

  .tile-entry-link {
    float: left;
    width: 50%;
    height: 50vh;
  }
}

.ajax-receiver {
  width: calc(100% - 430px);
  margin-left: 430px;

  .tiles {
    width: auto;
    margin-left: 0;
  }
}

.home, .page, .archive {
  .ajax-receiver {
    .tiles {
      width: 100%;
    }
  }

  .tile-cluster {
    width: 100%;
    float: left;
  }
}

.single-store, .single-post, .single-event, .single-news, .error404, .jubileum {
  .main {
    position: absolute;
  }

  .tiles {
    width: calc(100% - 860px);
    margin-left: 860px;

    .tile {
      width: 100%;
    }

    .tile-upper {
      clear: both;
    }

    .tile-lower {
      clear: both;
    }

    .tile-overflow {
      display: none;
    }
  }
}

.store-slides {
  width: calc(100% - 430px);
  height: 100%;
  left: 430px;
}

@media (max-width: 1024px) {
  .sidebar, .main {
    width: 330px;
  }

  .main {
    margin-left: 330px;
  }

  .tiles, #footer .site-menu .main, .ajax-receiver {
    width: calc(100% - 330px);
    margin-left: 330px;
  }

  .editions {
    margin-left: 330px;

    .tiles {
      width: 50%;
      margin-left: 0px;

      .tile {
        width: 100%;
      }
    }

    .tiles, .edition-list {
      float: left;
    }
  }

  .single-store, .single-post, .single-event, .single-news, .error404, .jubileum {
    .tiles {
      width: calc(100% - 660px);
      margin-left: 660px;
    }
  }

  .store-slides {
    width: calc(100% - 330px);
    height: 100%;
    left: 330px;
  }

  .tile-entry-links {
    width: calc(100% - 330px);
  }
}

@media (max-width: 768px) {
  body {
    background-color: #fff;
  }

  .sidebar, .main, .ajax-receiver {
    width: 50%;
  }

  .sidebar {
    border-right: 0 !important;
  }

  .main {
    margin-left: 50%;
  }

  .tiles, #footer .site-menu .main, .ajax-receiver {
    margin-left: 50%;

    .tile {
      width: 100%;
    }

    .tile-slides {
      &:nth-child(2), &:nth-child(4) {
        display: none;
      }
    }
  }

  .editions {
    margin-left: 50%;

    .tiles {
      width: 100%;
      margin-left: 0px;

      .tile {
        width: 100%;
      }
    }

    .tiles, .edition-list {
      float: left;
      width: 100% !important;
      height: 50vh !important;
    }
  }

  #footer .site-menu .main {
    width: 50%;
  }

  .single-post .tiles, .tiles.stores, .store-slides {
    display: none;
  }
}

@media (max-width: 668px) {
  #footer {
    display: none;
  }

  .home {
    .ajax-receiver {
      width: 100%;
      margin-left: 0;
      height: calc(100vh - 61px);
      overflow: hidden;

      .tile-slides {
        height: calc(50vh - 30.5px);

        &:nth-child(2), &:nth-child(4) {
          display: none;
        }

        .tile {
          height: calc(50vh - 30.5px);
        }
      }
    }

    .sidebar-intro {
      display: none;
    }
  }

  .single-store, .single-post, .single-event, .single-news, .archive, .page, .error404, .jubileum {
    .tiles, .ajax-receiver, .sidebar-tiles {
      display: none;
    }

    .archive-pagination {
      display: block;
    }

    .main {
      position: relative;
      height: auto;

      article {
        height: auto;

        .entry-footer {
          position: relative;
        }
      }
    }
  }

  .archive {
    .sidebar {
      .logo, .sidebar-intro {
        display: none;
      }

      .sidebar-content {
        display: block;
      }
    }
  }

  .sidebar, .main {
    width: 100%;
    float: none;
  }

  .main {
    margin-left: 0;
  }

  .tiles, #footer .site-menu .main {
    width: 100%;
    margin-left: 0;

    .tile {
      width: 100%;
    }
  }

  .single-store, .single-post, .single-event, .single-news, .error404, .jubileum {
    .tiles {
      display: none;
    }
  }
}

@media (min-width: 1024px) {
  .editions {
    @include clearfix;
    width: calc(100% - 330px);
    margin-left: 330px;

    .tiles {
      float: left;
      width: 50%;
      margin-left: 0;

      .tile {
        width: 100%;
      }

      .pagination {
        display: none;
      }
    }

    .edition-list {
      float: left;
    }
  }
}

@media (min-width: 1910px) {
  .sidebar, .main {
    width: 600px;
  }

  .sidebar {
    .logo {
      img {
        height: 130px !important;
      }
    }
  }

  .main {
    margin-left: 600px;
  }

  .editions {
    width: calc(100% - 600px);
    margin-left: 600px;
  }

  .tiles, #footer .site-menu .main, .ajax-receiver {
    width: calc(100% - 600px);
    margin-left: 600px;
  }

  .single-store, .single-post, .single-event, .single-news, .error404, .jubileum {
    .tiles {
      width: calc(100% - 1200px);
      margin-left: 1200px;
    }
  }

  .store-slides {
    width: calc(100% - 600px);
    left: 600px;
  }

  .tile-entry-links {
    width: calc(100% - 600px);
  }
}

// :(
@media (min-width: 1921px) and (min-height: 1081px) {
  .container-fluid {
    outline: 1px solid #bbbbbb;
    position: relative !important;
  }

  .container-fluid, #footer {
    width: 1910px !important;
    height: 1080px !important;
    margin: 0 auto !important;
    overflow: hidden;
  }

  #footer {
    left: 50% !important;
    transform: translateX(-50%);
  }

  .main, .sidebar, .single-post .tiles, .jubileum .tiles {
    height: 1080px !important;
    overflow: hidden;

    article, .sidebar-tiles, .sidebar-tiles .sidebar-tile, .tiles {
      height: 1080px !important;
    }
  }

  .sidebar {
    article {
      height: auto !important;
    }
  }

  .stores, .store-slides {
    .store {
      height: 1080px !important;
    }
  }

  .store-slides {
    width: 1310px !important;
    left: auto !important;
    right: 0 !important;
    position: absolute !important;
  }

  .tiles {
    &.posts, &.stores {
      .tile-slides {
        height: 540px;

        .tile {
          height: 540px;
        }
      }
    }
  }

  .ajax-receiver, .ajax-receiver .ajax-receiver-inner {
    height: 1080px !important;

    .tiles, .tiles .pagination {
      height: 1080px !important;

      .tile-slides {
        height: 50%;

        .tile {
          height: 540px;
        }
      }
    }
  }
}
