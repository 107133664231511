@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-Hairline.eot');
  src: url('../fonts/KarbonWeb-Hairline.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KarbonWeb-Hairline.woff2') format('woff2'),
       url('../fonts/KarbonWeb-Hairline.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-HairlineItalic.eot');
  src: url('../fonts/KarbonWeb-HairlineItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KarbonWeb-HairlineItalic.woff2') format('woff2'),
       url('../fonts/KarbonWeb-HairlineItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-Thin.eot');
  src: url('../fonts/KarbonWeb-Thin.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KarbonWeb-Thin.woff2') format('woff2'),
       url('../fonts/KarbonWeb-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-ThinItalic.eot');
  src: url('../fonts/KarbonWeb-ThinItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KarbonWeb-ThinItalic.woff2') format('woff2'),
       url('../fonts/KarbonWeb-ThinItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-Light.eot');
  src: url('../fonts/KarbonWeb-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KarbonWeb-Light.woff2') format('woff2'),
       url('../fonts/KarbonWeb-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-LightItalic.eot');
  src: url('../fonts/KarbonWeb-LightItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KarbonWeb-LightItalic.woff2') format('woff2'),
       url('../fonts/KarbonWeb-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-Regular.eot');
  src: url('../fonts/KarbonWeb-Regular.woff2') format('woff2'),
       url('../fonts/KarbonWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-Regular.eot');
  src: url('../fonts/KarbonWeb-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KarbonWeb-Regular.woff2') format('woff2'),
       url('../fonts/KarbonWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-Medium.eot');
  src: url('../fonts/KarbonWeb-Medium.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KarbonWeb-Medium.woff2') format('woff2'),
       url('../fonts/KarbonWeb-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-MediumItalic.eot');
  src: url('../fonts/KarbonWeb-MediumItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KarbonWeb-MediumItalic.woff2') format('woff2'),
       url('../fonts/KarbonWeb-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-Semibold.eot');
  src: url('../fonts/KarbonWeb-Semibold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KarbonWeb-Semibold.woff2') format('woff2'),
       url('../fonts/KarbonWeb-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-SemiboldItalic.eot');
  src: url('../fonts/KarbonWeb-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KarbonWeb-SemiboldItalic.woff2') format('woff2'),
       url('../fonts/KarbonWeb-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-Bold.eot');
  src: url('../fonts/KarbonWeb-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KarbonWeb-Bold.woff2') format('woff2'),
       url('../fonts/KarbonWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon';
  src: url('../fonts/KarbonWeb-BoldItalic.eot');
  src: url('../fonts/KarbonWeb-BoldItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/KarbonWeb-BoldItalic.woff2') format('woff2'),
       url('../fonts/KarbonWeb-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
