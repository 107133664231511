form {
  input[type], textarea {
    &:focus {
      box-shadow: none !important;
    }
  }

  label {
    font-size: 11px;
    line-height: 15px;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;

    @media (min-width: 1910px) {
      font-size: 15px;
      line-height: 19px;
    }
  }

  .btn {
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase;
    font-weight: normal;

    @media (min-width: 1910px) {
      font-size: 15px;
      line-height: 19px;
    }

    &.btn-link {
      color: #000;
      padding-left: 0;
    }

    .btn-arrow-left, .btn-arrow-right {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      color: #000;
      border: 1px solid #000;

      svg {
        width: 0.8em;
        height: 0.8em;
      }

      @media (max-width: 1910px) {
        width: 14px;
        height: 14px;
        margin-left: 2px;
        margin-right: 26px;

        svg {
          width: 0.6em;
          height: 0.6em;
        }
      }

      @media (max-width: 1024px) {
        width: 12px;
        height: 12px;
        margin-left: 2px;
        margin-right: 20px;

        svg {
          width: 0.4em;
          height: 0.4em;
        }
      }
    }

    .btn-arrow-left {
      float: left;
      margin-right: 10px;
    }

    .btn-arrow-right {
      float: right;
      margin-left: 10px;
    }
  }
}

#mailchimp-signup {
  .form-group {
    input[type="text"], input[type="email"] {
      max-width: 400px;
    }
  }
}

#mailchimp-message, #mailchimp-success, #mailchimp-error {
  display: none;
}
