// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #a0c875;

$grey:                  #eee9e9;
$darkgrey:              #373737;

// Fonts
$font-family-sans-serif:  "Karbon", "Rubik", Helvetica, Arial, sans-serif;
$font-size-base:        13px;

// Navbar
$navbar-height:                    60px;
$navbar-default-color:             $darkgrey;
$navbar-default-bg:                #fff;
$navbar-default-border:            $darkgrey;
$navbar-border-radius:             0;
$navbar-default-link-color:        $darkgrey;
$navbar-default-link-hover-color:  #000;
$navbar-default-link-hover-bg:     $grey;
$navbar-default-link-active-color: #000;
$navbar-default-link-active-bg:    $grey;

// Navbar toggle
$navbar-default-toggle-hover-bg:           transparent;
$navbar-default-toggle-icon-bar-bg:        #000;
$navbar-default-toggle-border-color:       transparent;

// Dropdown
$dropdown-link-active-color:     #000;
$dropdown-link-active-bg:        transparent;

// Forms
//** Text color for `<input>`s
$input-color:                    $darkgrey;
//** `<input>` border color
$input-border:                   $brand-primary;

$input-border-radius:            0;
//** Large `.form-control` border radius
$input-border-radius-large:      0;
//** Small `.form-control` border radius
$input-border-radius-small:      0;

//** Border color for inputs on focus
$input-border-focus:             darken($brand-primary, 20%);

//** Placeholder text color
$input-color-placeholder:        $brand-primary;

//** `.form-group` margin
$form-group-margin-bottom:       10px;

// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: 669px;

// retina.scss
// A helper mixin for applying high-resolution background images (http://www.retinajs.com)
@mixin at2x($path, $ext: "jpg", $w: auto, $h: auto) {
  $at1x_path: "#{$path}.#{$ext}";
  $at2x_path: "#{$path}@2x.#{$ext}";

  background-image: url("#{$at1x_path}");

  @media all and (-webkit-min-device-pixel-ratio : 1.5),
    all and (-o-min-device-pixel-ratio: 3/2),
    all and (min--moz-device-pixel-ratio: 1.5),
    all and (min-device-pixel-ratio: 1.5) {
      background-image: url("#{$at2x_path}");
      background-size: $w $h;
  }
}
