.header {
  position: relative;
  z-index: 1;

  .navigation {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    font-size: 15px;
    text-transform: uppercase;
    padding: 0;
    margin-bottom: 0;

    @media (min-width: 1910px) {
      font-size: 20px;
    }

    @media (max-width: 1024px) {
      font-size: 11px;
    }

    .close-icon {
      display: none;
    }

    .navbar-brand {
      padding: 10px;

      img {
        height: 40px;
      }
    }

    .navbar-collapse {
      padding: 0;
    }

    .navbar-header, .mobile-item {
      display: none;
    }

    .nav {
      li {
        border-right: 1px solid #000;
        padding: 10px;

        a {
          padding: 11px 10px;

          @media (max-width: 1024px) {
            padding-left: 5px;
            padding-right: 5px;
          }

          &:focus {
            background-color: transparent !important;
          }
        }

        &.menu-item-has-children:hover {
          > a {
            background: $grey !important;
          }
        }

        &.current_page_parent {
          > a {
            background-color: $grey;
          }
        }

        &.btn-toggle-item {
          padding-left: 0px;
          padding-right: 0px;
        }

        .btn-toggle {
          padding: 12px 10px 7px 10px;

          svg {
            width: 1.1em;
            height: 1.1em;
          }

          &:hover, &:active {
            color: #fff;
            background-color: #000;
          }
        }

        &:first-child {
          border-right: 0;
        }

        &:last-child, &.last {
          border-right: 0;
        }

        .dropdown-menu {
          background-color: #fff;
          box-shadow: none;
          border: 0;
          border-bottom: 1px solid #000;
          border-radius: 0;
          font-size: 14px;
          padding: 5px 0;
          margin-top: 1px;

          @media (min-width: 1910px) {
            font-size: 18px;
          }

          @media (max-width: 1024px) {
            font-size: 11px;
          }

          li {
            padding: 2px 0;
            border-right: 0;

            .nav-item-arrow {
              width: 18px;
              height: 18px;
              border: 1px solid $darkgrey;
              border-radius: 50%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-left: 0px;
              margin-right: 25px;
              text-align: center;
              visibility: hidden;

              svg {
                width: 0.8em;
                height: 0.8em;
              }

              @media (max-width: 1910px) {
                width: 14px;
                height: 14px;
                margin-left: 2px;
                margin-right: 26px;

                svg {
                  width: 0.6em;
                  height: 0.6em;
                }
              }

              @media (max-width: 1024px) {
                width: 12px;
                height: 12px;
                margin-left: 2px;
                margin-right: 20px;

                svg {
                  width: 0.4em;
                  height: 0.4em;
                }
              }
            }

            a {
              padding: 0 10px;

              &:hover, &:active, &.active {
                font-weight: 500;
                background-color: transparent;
              }
            }

            &:hover, &:active {
              .nav-item-arrow {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
}

.single-store {
  .header {
    .navigation {
      padding: 0 5px;

      .nav {
        .dropdown-menu {
          background-color: transparentize(#fff, 0.1);
        }
      }
    }
  }
}

.menu-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  left: 0;
  z-index: 2;
  display: none;

  @media (max-width: 1024px) {
    display: none !important;
  }
}

@media (max-width: 668px) {
  .header {
    .navigation {
      .navbar-header, .mobile-item {
        display: block;
      }

      .regular-item {
        display: none;
      }

      .navbar-collapse {
        padding: 0 10px;
      }

      .nav li {
        border-right: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      .btn-toggle-item, .nav-item-arrow {
        display: none !important;
      }
    }
  }
}

ul.menu-buttons {
  position: fixed;
  top: 128px;
  margin-left: 5px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 1;

  @media (min-width: 1910px) {
    top: 129px;
  }

  @media (max-width: 668px) {
    display: none;
  }

  li span.menu-button-toggle {
    border: 0;
    padding: 10px 12px;
    background-color: $grey;
    display: block;
    cursor: pointer;

    @media (min-width: 1910px) {
      padding: 6px 12px;
    }

    .menu-button-toggle-arrow {
      display: block;
      transform: rotate(-90deg);
      transition: transform 300ms;
    }

    &:focus {
      outline: none;
    }

    &:hover, &:active {
      background-color: #000;
      color: $grey;

      .menu-button-toggle-arrow {
        transform: rotate(0deg);
      }
    }
  }

  ul.menu-buttons-submenu {
    transform-origin: top;
    animation-fill-mode: forwards;
    transform: scale(1, 0);
    transition: transform 0.3s ease-out;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid #000;

    li {
      a {
        display: block;
        color: #999;
        border-bottom: 1px solid #999;
        background-color: $grey;
        padding: 10px 12px;

        @media (min-width: 1910px) {
          padding: 6px 12px;
        }

        &:hover, &:active {
          color: #000;
          border-color: #000;
          //background-color: #999;
        }

        @media (max-width: 668px) {
          &.menu-button-menu-toggle {
            display: none;
          }
        }
      }

      &:last-child {
        a {
          border-bottom: 0;
        }
      }
    }
  }

  &.open {
    li {
      button {
        .menu-button-toggle-arrow {
          transform: rotate(0deg);
        }

        /*&:hover, &:active {
          svg {
            transform: rotate(-90deg);
          }
        }*/
      }

      ul.menu-buttons-submenu {
        //display: block;
        transform: scale(1, 1);
      }
    }
  }
}

.single-store {
  ul.menu-buttons {
    display: none;
  }
}

@media (max-width: 668px) {
  .home {
    ul.menu-buttons {
      display: block;
    }
  }
}

@-moz-document url-prefix() {
  ul.menu-buttons {
    top: 127px;

    @media (min-width: 1910px) {
      top: 128px;
    }
  }
}
