body {
  text-rendering: optimizeLegibility;
  background-color: $grey;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 1910px) {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  html {
    overflow: hidden;
    height: 100%;
  }

  body {
    height: 100%;
    overflow: auto;
  }
}

.incompatibility-notice {
  margin: 0;
  border-radius: 0;
  border: 0;
  color: #fff;
  background-color: $brand-primary;

  a {
    color: #fff;
    text-decoration: underline;

    &:hover, &:active {
      text-decoration: none;
    }
  }
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  font-size: 13px;
}

.ajax-container {
  display: none;
}

.ajax-receiver {
  overflow: hidden;

  @media (max-width: $screen-sm-min) {
    overflow: auto;
  }

  .ajax-receiver-inner {
    height: 100vh;
  }
}

#cookie-notice {
  .cookie-notice-container {
    .button {
      background: $brand-primary;
      border-radius: 0;
      text-shadow: none;
      color: #000;
      border: 0;
      box-shadow: none;

      &:hover, &:active {
        background: darken($brand-primary, 20%);
      }
    }
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .loader-inner {
    color: $brand-primary;
    text-indent: -99999em;
    position: relative;
    width: 2.4em;
    height: 2.4em;
    box-shadow: inset 0 0 0 0.3em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 50%;
    margin: 0 auto;

    &:before, &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
    }

    &:before {
      width: 1.3em;
      height: 2.6em;
      background: #fff;
      border-radius: 2.6em 0 0 2.6em;
      top: -0.1em;
      left: -0.1em;
      -webkit-transform-origin: 1.3em 1.2em;
      transform-origin: 1.3em 1.2em;
      -webkit-animation: loader 2s infinite ease 1.5s;
      animation: loader 2s infinite ease 1.5s;
    }

    &:after {
      width: 1.4em;
      height: 2.6em;
      background: #fff;
      border-radius: 0 2.6em 2.6em 0;
      top: -0.1em;
      left: 1.2em;
      -webkit-transform-origin: 0px 1.3em;
      transform-origin: 0px 1.3em;
      -webkit-animation: loader 2s infinite ease;
      animation: loader 2s infinite ease;
    }
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ps-container {
  >.ps-scrollbar-y-rail {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 5px;
    overflow: hidden;
    background-color: #eee !important;

    &:hover {
      opacity: 1 !important;
    }

    >.ps-scrollbar-y {
      background-color: darken($grey, 9);
      border-radius: 0;
      width: 5px;
      right: 0;
    }
  }
}

// Dropdown animation
.open > .dropdown-menu {
  transform: scale(1, 1);
}

.dropdown-menu {
  transform-origin: top;
  animation-fill-mode: forwards;
  transform: scale(1, 0);
  display: block;
  transition: transform 0.3s ease-out;
}
.dropup .dropdown-menu {
  transform-origin: bottom;
}

.dropup > .dropdown-menu:after {
  top: auto;
  display: inline-block;
  bottom: -6px;
  content: '';
  position: absolute;
  left: 50%;
}

// Pagination
.archive-pagination {
  text-align: center;
}

.pagination {
  li {
    display: inline-block;

    a, span {
      color: $darkgrey;
      border-color: transparent;
      border-radius: 0 !important;
      padding-top: 0px;
      padding-bottom: 0px;

      &:hover, &:active {
        background-color: transparent;
        border-color: transparent;
        color: #000;
      }

      &.disabled {
        display: none;
      }

      &.page-number {
        border-right: 1px solid $darkgrey;
      }

      &.btn {
        &.btn-arrow-left {
          margin-right: 15px;
        }

        &.btn-arrow-right {
          margin-left: 15px;
        }
      }
    }
  }

  .current {
    a, span {
      color: $grey;
      border-color: transparent;
      border-right-color: $darkgrey;

      &:focus, &:hover {
        color: $grey;
        border-right-color: $darkgrey;
      }

      &.current {
        color: $brand-primary;
      }
    }
  }
}

// Remove outlines from carousel
.slick-slide {
  &:focus, a {
    outline: none;
  }
}

// Fix SVG dimensions
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%;
  }
}

.entry-social-links, .social-links {
  h4 {
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0px;
    margin-bottom: 10px;

    @media (min-width: 1910px) {
      font-size: 15px;
      line-height: 19px;
    }
  }

  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #000;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    margin-right: 5px;

    &.social-link-facebook {
      background-color: #1898cb;
    }

    &.social-link-twitter {
      background-color: #1eb4c4;
    }

    .icon {
      font-size: 12px;
    }
  }
}

.notice-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: opacity 0.8s ease-out;
  opacity: 0;
  height: 0;

  &.active {
    opacity: 1;
    height: 100%;
  }

  .notice {
    width: 375px;
    height: 375px;
    background-color: #fff;
    padding: 8px;
    border: 1px solid $grey;

    .notice-inner {
      width: 100%;
      height: 100%;
      border: 1px solid #000;
      text-align: center;

      h3 {
        font-size: 19px;
        line-height: 19px;
        font-weight: 600;
        margin-top: 40px;
        text-transform: uppercase;

        @media (max-width: 1024px) {
          font-size: 17px;
          line-height: 20px;
        }
      }

      .notice-image {
        position: relative;
        height: 179px;
        border-bottom: 1px solid #000;
        background-color: #000;
        background-size: cover;

        .notice-close {
          width: 24px;
          height: 24px;
          position: absolute;
          top: 0;
          right: 0;
          color: #fff;
          padding: 0;
          border: 1px solid #000;
          border-top: 0;
          border-right: 0;
          background-color: #000;

          .icon {
            width: 24px;
            height: 24px;
          }

          &:hover, &:active {
            background-color: $brand-primary;
          }
        }
      }

      hr {
        border: none;
        height: 1px;
        color: #000;
        background-color: #000;
        margin: 10px 20%;
      }

      .notice-body {
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;

        @media (max-width: 1024px) {
          font-size: 15px;
          line-height: 18px;
        }
      }

      .notice-footer {
        a {
          font-weight: 500;
          font-size: 17px;
          line-height: 20px;
          color: #000;

          .item-arrow {
            width: 18px;
            height: 18px;
            border: 1px solid $darkgrey;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 0px;
            margin-right: 25px;
            text-align: center;

            svg {
              width: 0.8em;
              height: 0.8em;
            }

            @media (max-width: 1024px) {
              width: 12px;
              height: 12px;
              margin-left: 2px;
              margin-right: 20px;

              svg {
                width: 0.4em;
                height: 0.4em;
              }
            }
          }
        }
      }
    }
  }

  .notice-embed {
    width: calc(80vw - 30px);
    max-width: 177.78vh;
    height: 56.25vw;
    max-height: calc(80vh - 30px);

    .notice-image {
      height: 100% !important;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

// Contact Form
#contact-form-container {
  @include clearfix;
  clear: both;

  #contact-form-message, #contact-form-success, #contact-form-error {
    display: none;
  }

  .loader {
    min-height: 200px;
  }
}

#contact-form {
  @include clearfix;
  clear: both;
  width: 425px;
  padding-top: 20px;
  font-size: 11px;
  line-height: 15px;

  @media (min-width: 1910px) {
    font-size: 15px;
    line-height: 19px;
  }

  @media (max-width: 1170px) {
    width: 100%;
  }

  h4 {
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0px;

    @media (min-width: 1910px) {
      font-size: 15px;
      line-height: 19px;
    }
  }

  .radio {
    margin-top: 5px;
    margin-bottom: 5px;

    &:first-child {
      margin-top: 8px;
    }
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label {
    color: #000;
    padding-left: 0;
  }

  input[type="radio"] + label span {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin:-1px 10px 0 0;
    vertical-align: middle;
    cursor: pointer;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 0px solid #000;

    @media (max-width: 1910px) {
      width: 14px;
      height: 14px;
    }

    @media (max-width: 1024px) {
      width: 0.6em;
      height: 0.6em;
    }
  }

  input[type="radio"] + label:hover span,
  input[type="radio"]:checked + label span {
    background-image: url('../images/contact-select.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
    border-width: 1px;
  }

  .stores, .contact-fields {
    float: left;
  }

  .stores {
    max-width: 137px;
    width: 100%;
    margin-right: 30px;

    @media (min-width: 1910px) {
      max-width: 186px;
    }
  }

  .contact-fields {
    width: 100%;
    max-width: 200px;
  }
}
