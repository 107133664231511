#footer {
  position: fixed;
  z-index: 1000;
  top: 100vh;
  transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000) 0.3s; /* easeOutExpo */
  transition: top 0.5s;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-top: 1px solid #000;

  @media (min-width: 2500px) and (min-height: 1080px) {
    transition: none;
  }

  &.active {
    transition-timing-function: cubic-bezier(0.950, 0.050, 0.795, 0.035) 0.3s; /* easeInExpo */
    top: -1px;
    left: 0;
  }

  .site-menu {
    .sidebar {
      border-right: 0;
      padding: 0;

      .header {
        padding-right: 5px;
        border-right: 1px solid #000;

        .navigation {
          padding-left: 10px;
          padding-right: 0;

          .dropdown-menu {
            border-right: 1px solid #000;

            .nav-item-arrow {
              margin-left: 10px;

              @media (max-width: 1024px) {
                margin-left: 15px;
              }
            }
          }
        }

        .menu-icon {
          display: none;
        }

        .btn-toggle-item {
          padding-right: 0;

          .btn-toggle {
            padding: 10px 8px 9px 8px;
          }
        }

        .close-icon {
          display: block;
          padding-left: 0px;
          padding-right: 0px;
          line-height: 0;

          svg {
            width: 1.3em;
            height: 1.3em;
          }
        }
      }

      .sidebar-inner {
        border-right: 1px solid #000;
        height: calc(100% - 450px);
        position: relative;
      }

      .sidebar-slider {
        position: absolute;
        left: 0;
        bottom: 0;
        width: calc(100% - 4px);
        height: 335px;

        .slide {
          background: no-repeat center;
          background-size: cover;
          width: 100%;
          height: 335px;
        }
      }
    }

    .menu-buttons {
      display: none;
    }

    .main {
      border-right: 0;
      position: relative;

      .main-header {
        border-bottom: 1px solid #000;
        margin: 0 5px;

        .navbar {
          font-size: 15px;
          text-transform: uppercase;
          border: 0;
          margin: 0;

          @media (min-width: 1910px) {
            font-size: 20px;
          }

          @media (max-width: 1024px) {
            font-size: 11px;
          }

          li {
            padding: 11px 5px;

            &.active {
              a {
                background-color: transparent;

                span {
                  display: block;
                  background-color: transparentize($brand-primary, 0.85);
                }
              }
            }

            a {
              padding: 0;
              padding-right: 10px;
              color: $brand-primary;
              border-right: 1px solid $brand-primary;

              span {
                display: block;
                padding: 10px;

                @media (max-width: 768px) {
                  padding: 10px;
                }
              }

              &:hover, &:active, &:focus, &.focus {
                background-color: transparent;

                span {
                  display: block;
                  background-color: transparentize($brand-primary, 0.85);
                }
              }
            }
          }
        }
      }

      .main-body {
        @include clearfix;
        padding-top: 30px;

        .main-opening-times, .main-page {
          float: left;
          max-width: 425px;
          height: 100%;
          position: relative;

          @media (min-width: 1910px) {
            max-width: 100%;
            width: 50%;
          }

          @media (max-width: 1280px) {
            max-width: 100%;
            width: 50%;
          }

          h3 {
            @media (min-width: 1910px) {
              font-size: 20px;
              line-height: 20px;
            }
          }

          .main-opening-times-text {
            font-size: 11px;
            line-height: 15px;

            @media (min-width: 1910px) {
              font-size: 15px;
              line-height: 19px;
            }

            h3 {
              font-size: 11px;
              line-height: 15px;
              font-weight: 500;
              margin: 0;

              @media (min-width: 1910px) {
                font-size: 15px;
                line-height: 19px;
              }
            }
          }

          @media (max-width: 1024px) {
            width: 50%;
            border-right: 0 !important;
          }

          @media (max-width: 768px) {
            width: 100%;
            height: 50%;
          }

          .main-opening-times-inner, .main-page-inner {
            max-width: 425px;
            padding: 20px 15px;

            @media (min-width: 1910px) {
              max-width: 100%;
              padding: 20px;
            }
          }
        }

        .main-page {
          #map {
            margin-top: 20px;
            width: 100%;
            height: 200px;
            display: block;
            cursor: pointer;
          }
        }

        h3 {
          font-size: 15px;
          line-height: 18px;
          font-weight: normal;
          margin: 0;
          margin-bottom: 30px;
          text-transform: uppercase;
        }

        .main-opening-times {
          border-right: 1px solid #000;

          .main-opening-times-text {
            .main-opening-times-text-column {
              float: left;
              margin-right: 15px;

              @media (min-width: 1280px) {
                width: 30%;
              }

              @media (min-width: 1910px) {
                max-width: 29%;
                margin-right: 35px;
              }

              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }

        .contact-column {
          float: left;
          margin-right: 35px;
          font-size: 11px;
          line-height: 15px;
          text-transform: uppercase;

          @media (min-width: 1910px) {
            font-size: 15px;
            line-height: 19px;
          }

          &:last-child {
            margin-right: 0;
          }

          h4 {
            font-size: 11px;
            line-height: 15px;
            font-weight: 500;
            text-transform: uppercase;
            margin: 0px;

            @media (min-width: 1910px) {
              font-size: 15px;
              line-height: 19px;
            }
          }

          a {
            color: #000;
            text-decoration: none;

            &:hover, &:active {
              text-decoration: underline;
            }
          }

          &.social-links {
            a {
              color: #fff;
              margin-top: 5px;
            }
          }
        }
      }

      .main-slider {
        background-color: $brand-primary;
        position: absolute;
        left: 4px;
        bottom: 0;
        width: 100%;
        height: 335px;

        .slide {
          background: no-repeat center;
          background-size: cover;
          width: 100%;
          height: 335px;
        }
      }
    }
  }
}

@media (max-width: 1024px), (max-height: 800px) {
  #footer {
    .site-menu {
      .sidebar, .main {
        .sidebar-inner {
          height: calc(100% - 335px);
        }

        .sidebar-slider, .main-slider {
          height: 265px;

          .slide {
            height: 265px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #footer {
    width: 100vw;
  }
}

@media (min-width: 1910px) {
  #footer {
    .site-menu {
      .sidebar, .main {
        .sidebar-inner {
          height: calc(100% - 475px);
        }

        .sidebar-slider, .main-slider {
          height: 405px;

          .slide {
            height: 405px;
          }
        }
      }
    }
  }
}

@media (min-height: 1080px) {
  #footer {
    .site-menu {
      .sidebar, .main {
        .sidebar-inner {
          height: calc(100% - 575px);
        }

        .sidebar-slider, .main-slider {
          height: 505px;

          .slide {
            height: 505px;
          }
        }
      }
    }
  }
}
