body#tinymce {
  margin: 12px !important;
  color: #000;
  background-color: #fff;

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  h1, h2, h3 {
    font-size: 14px;
    line-height: 14px;
    font-weight: normal;
    text-transform: uppercase;
  }

  h3, h4, h5, h6 {
    margin-top: 20px;
  }

  h3 {
    &:first-of-type {
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  strong {
    font-weight: 500;
  }

  h4 {
    font-size: 13px;
  }

  h5 {
    font-size: 12px;
  }

  h6 {
    font-size: 12px;
    font-style: italic;
  }

  img {
    max-width: 100%;
  }

  a {
    color: $darkgrey;
    text-decoration: underline;

    &:hover, &:active {
      color: #000;
    }
  }
}
